import React from 'react';
import AC from './AC.svg'
import AD from './AD.svg'
import AE from './AE.svg'
import AF from './AF.svg'
import AG from './AG.svg'
import AI from './AI.svg'
import AL from './AL.svg'
import AM from './AM.svg'
import AO from './AO.svg'
import AQ from './AQ.svg'
import AR from './AR.svg'
import AS from './AS.svg'
import AT from './AT.svg'
import AU from './AU.svg'
import AW from './AW.svg'
import AX from './AX.svg'
import AZ from './AZ.svg'
import BA from './BA.svg'
import BB from './BB.svg'
import BD from './BD.svg'
import BE from './BE.svg'
import BF from './BF.svg'
import BG from './BG.svg'
import BH from './BH.svg'
import BI from './BI.svg'
import BJ from './BJ.svg'
import BL from './BL.svg'
import BM from './BM.svg'
import BN from './BN.svg'
import BO from './BO.svg'
import BQ from './BQ.svg'
import BR from './BR.svg'
import BS from './BS.svg'
import BT from './BT.svg'
import BV from './BV.svg'
import BW from './BW.svg'
import BY from './BY.svg'
import BZ from './BZ.svg'
import CA from './CA.svg'
import CC from './CC.svg'
import CD from './CD.svg'
import CF from './CF.svg'
import CG from './CG.svg'
import CH from './CH.svg'
import CI from './CI.svg'
import CK from './CK.svg'
import CL from './CL.svg'
import CM from './CM.svg'
import CN from './CN.svg'
import CO from './CO.svg'
import CR from './CR.svg'
import CU from './CU.svg'
import CV from './CV.svg'
import CW from './CW.svg'
import CX from './CX.svg'
import CY from './CY.svg'
import CZ from './CZ.svg'
import DE from './DE.svg'
import DJ from './DJ.svg'
import DK from './DK.svg'
import DM from './DM.svg'
import DO from './DO.svg'
import DZ from './DZ.svg'
import EC from './EC.svg'
import EE from './EE.svg'
import EG from './EG.svg'
import EH from './EH.svg'
import ER from './ER.svg'
import ES from './ES.svg'
import ET from './ET.svg'
import FI from './FI.svg'
import FJ from './FJ.svg'
import FK from './FK.svg'
import FM from './FM.svg'
import FO from './FO.svg'
import FR from './FR.svg'
import GA from './GA.svg'
import GB from './GB.svg'
import GD from './GD.svg'
import GE from './GE.svg'
import GF from './GF.svg'
import GG from './GG.svg'
import GH from './GH.svg'
import GI from './GI.svg'
import GL from './GL.svg'
import GM from './GM.svg'
import GN from './GN.svg'
import GP from './GP.svg'
import GQ from './GQ.svg'
import GR from './GR.svg'
import GS from './GS.svg'
import GT from './GT.svg'
import GU from './GU.svg'
import GW from './GW.svg'
import GY from './GY.svg'
import HK from './HK.svg'
import HM from './HM.svg'
import HN from './HN.svg'
import HR from './HR.svg'
import HT from './HT.svg'
import HU from './HU.svg'
import ID from './ID.svg'
import IE from './IE.svg'
import IL from './IL.svg'
import IM from './IM.svg'
import IN from './IN.svg'
import IO from './IO.svg'
import IQ from './IQ.svg'
import IR from './IR.svg'
import IS from './IS.svg'
import IT from './IT.svg'
import JE from './JE.svg'
import JM from './JM.svg'
import JO from './JO.svg'
import JP from './JP.svg'
import KE from './KE.svg'
import KG from './KG.svg'
import KH from './KH.svg'
import KI from './KI.svg'
import KM from './KM.svg'
import KN from './KN.svg'
import KP from './KP.svg'
import KR from './KR.svg'
import KW from './KW.svg'
import KY from './KY.svg'
import KZ from './KZ.svg'
import LA from './LA.svg'
import LB from './LB.svg'
import LC from './LC.svg'
import LI from './LI.svg'
import LK from './LK.svg'
import LR from './LR.svg'
import LS from './LS.svg'
import LT from './LT.svg'
import LU from './LU.svg'
import LV from './LV.svg'
import LY from './LY.svg'
import MA from './MA.svg'
import MC from './MC.svg'
import MD from './MD.svg'
import ME from './ME.svg'
import MF from './MF.svg'
import MG from './MG.svg'
import MH from './MH.svg'
import MK from './MK.svg'
import ML from './ML.svg'
import MM from './MM.svg'
import MN from './MN.svg'
import MO from './MO.svg'
import MP from './MP.svg'
import MQ from './MQ.svg'
import MR from './MR.svg'
import MS from './MS.svg'
import MT from './MT.svg'
import MU from './MU.svg'
import MV from './MV.svg'
import MW from './MW.svg'
import MX from './MX.svg'
import MY from './MY.svg'
import MZ from './MZ.svg'
import NA from './NA.svg'
import NC from './NC.svg'
import NE from './NE.svg'
import NF from './NF.svg'
import NG from './NG.svg'
import NI from './NI.svg'
import NL from './NL.svg'
import NO from './NO.svg'
import NP from './NP.svg'
import NR from './NR.svg'
import NU from './NU.svg'
import NZ from './NZ.svg'
import OM from './OM.svg'
import PA from './PA.svg'
import PE from './PE.svg'
import PF from './PF.svg'
import PG from './PG.svg'
import PH from './PH.svg'
import PK from './PK.svg'
import PL from './PL.svg'
import PM from './PM.svg'
import PN from './PN.svg'
import PR from './PR.svg'
import PS from './PS.svg'
import PT from './PT.svg'
import PW from './PW.svg'
import PY from './PY.svg'
import QA from './QA.svg'
import RE from './RE.svg'
import RO from './RO.svg'
import RS from './PT.svg'
import RU from './RU.svg'
import RW from './RW.svg'
import SA from './SA.svg'
import SB from './SB.svg'
import SC from './SC.svg'
import SD from './SD.svg'
import SE from './SE.svg'
import SG from './PT.svg'
import SH from './SH.svg'
import SI from './SI.svg'
import SJ from './SJ.svg'
import SK from './SK.svg'
import SL from './SL.svg'
import SM from './SM.svg'
import SN from './SN.svg'
import SO from './SO.svg'
import SR from './SR.svg'
import SS from './SS.svg'
import ST from './ST.svg'
import SV from './SV.svg'
import SX from './SX.svg'
import SY from './SY.svg'
import SZ from './SZ.svg'
import TA from './TA.svg'
import TC from './TC.svg'
import TD from './TD.svg'
import TG from './TG.svg'
import TH from './TH.svg'
import TJ from './TJ.svg'
import TK from './TK.svg'
import TL from './TL.svg'
import TM from './TM.svg'
import TN from './TN.svg'
import TO from './TO.svg'
import TR from './TR.svg'
import TT from './TT.svg'
import TV from './TV.svg'
import TW from './TW.svg'
import TZ from './TZ.svg'
import UA from './UA.svg'
import UG from './UG.svg'
import US from './US.svg'
import UY from './UY.svg'
import UZ from './UZ.svg'
import VA from './VA.svg'
import VC from './VC.svg'
import VE from './VE.svg'
import VG from './VG.svg'
import VI from './VI.svg'
import VN from './VN.svg'
import VU from './VU.svg'
import WF from './WF.svg'
import WS from './WS.svg'
import XK from './XK.svg'
import YE from './YE.svg'
import YT from './YT.svg'
import ZA from './ZA.svg'
import ZM from './ZM.svg'
import ZW from './ZW.svg'


export const flags = {
    AC: () => <img src={AC} alt="AC" css={{ height: '100%' }} />,
    AD: () => <img src={AD} alt="AD" css={{ height: '100%' }} />,
    AE: () => <img src={AE} alt="AE" css={{ height: '100%' }} />,
    AF: () => <img src={AF} alt="AF" css={{ height: '100%' }} />,
    AG: () => <img src={AG} alt="AG" css={{ height: '100%' }} />,
    AI: () => <img src={AI} alt="AI" css={{ height: '100%' }} />,
    AL: () => <img src={AL} alt="AL" css={{ height: '100%' }} />,
    AM: () => <img src={AM} alt="AM" css={{ height: '100%' }} />,
    AO: () => <img src={AO} alt="AO" css={{ height: '100%' }} />,
    AQ: () => <img src={AQ} alt="AQ" css={{ height: '100%' }} />,
    AR: () => <img src={AR} alt="AR" css={{ height: '100%' }} />,
    AS: () => <img src={AS} alt="AS" css={{ height: '100%' }} />,
    AT: () => <img src={AT} alt="AT" css={{ height: '100%' }} />,
    AU: () => <img src={AU} alt="AU" css={{ height: '100%' }} />,
    AW: () => <img src={AW} alt="AW" css={{ height: '100%' }} />,
    AX: () => <img src={AX} alt="AX" css={{ height: '100%' }} />,
    AZ: () => <img src={AZ} alt="AZ" css={{ height: '100%' }} />,
    BA: () => <img src={BA} alt="BA" css={{ height: '100%' }} />,
    BB: () => <img src={BB} alt="BB" css={{ height: '100%' }} />,
    BD: () => <img src={BD} alt="BD" css={{ height: '100%' }} />,
    BE: () => <img src={BE} alt="BE" css={{ height: '100%' }} />,
    BF: () => <img src={BF} alt="BF" css={{ height: '100%' }} />,
    BG: () => <img src={BG} alt="BG" css={{ height: '100%' }} />,
    BH: () => <img src={BH} alt="BH" css={{ height: '100%' }} />,
    BI: () => <img src={BI} alt="BI" css={{ height: '100%' }} />,
    BJ: () => <img src={BJ} alt="BJ" css={{ height: '100%' }} />,
    BL: () => <img src={BL} alt="BL" css={{ height: '100%' }} />,
    BM: () => <img src={BM} alt="BM" css={{ height: '100%' }} />,
    BN: () => <img src={BN} alt="BN" css={{ height: '100%' }} />,
    BO: () => <img src={BO} alt="BO" css={{ height: '100%' }} />,
    BQ: () => <img src={BQ} alt="BQ" css={{ height: '100%' }} />,
    BR: () => <img src={BR} alt="BR" css={{ height: '100%' }} />,
    BS: () => <img src={BS} alt="BS" css={{ height: '100%' }} />,
    BT: () => <img src={BT} alt="BT" css={{ height: '100%' }} />,
    BV: () => <img src={BV} alt="BV" css={{ height: '100%' }} />,
    BW: () => <img src={BW} alt="BW" css={{ height: '100%' }} />,
    BY: () => <img src={BY} alt="BY" css={{ height: '100%' }} />,
    BZ: () => <img src={BZ} alt="BZ" css={{ height: '100%' }} />,
    CA: () => <img src={CA} alt="CA" css={{ height: '100%' }} />,
    CC: () => <img src={CC} alt="CC" css={{ height: '100%' }} />,
    CD: () => <img src={CD} alt="CD" css={{ height: '100%' }} />,
    CF: () => <img src={CF} alt="CF" css={{ height: '100%' }} />,
    CG: () => <img src={CG} alt="CG" css={{ height: '100%' }} />,
    CH: () => <img src={CH} alt="CH" css={{ height: '100%' }} />,
    CI: () => <img src={CI} alt="CI" css={{ height: '100%' }} />,
    CK: () => <img src={CK} alt="CK" css={{ height: '100%' }} />,
    CL: () => <img src={CL} alt="CL" css={{ height: '100%' }} />,
    CM: () => <img src={CM} alt="CM" css={{ height: '100%' }} />,
    CN: () => <img src={CN} alt="CN" css={{ height: '100%' }} />,
    CO: () => <img src={CO} alt="CO" css={{ height: '100%' }} />,
    CR: () => <img src={CR} alt="CR" css={{ height: '100%' }} />,
    CU: () => <img src={CU} alt="CU" css={{ height: '100%' }} />,
    CV: () => <img src={CV} alt="CV" css={{ height: '100%' }} />,
    CW: () => <img src={CW} alt="CW" css={{ height: '100%' }} />,
    CX: () => <img src={CX} alt="CX" css={{ height: '100%' }} />,
    CY: () => <img src={CY} alt="CY" css={{ height: '100%' }} />,
    CZ: () => <img src={CZ} alt="CZ" css={{ height: '100%' }} />,
    DE: () => <img src={DE} alt="DE" css={{ height: '100%' }} />,
    DJ: () => <img src={DJ} alt="DJ" css={{ height: '100%' }} />,
    DK: () => <img src={DK} alt="DK" css={{ height: '100%' }} />,
    DM: () => <img src={DM} alt="DM" css={{ height: '100%' }} />,
    DO: () => <img src={DO} alt="DO" css={{ height: '100%' }} />,
    DZ: () => <img src={DZ} alt="DZ" css={{ height: '100%' }} />,
    EC: () => <img src={EC} alt="EC" css={{ height: '100%' }} />,
    EE: () => <img src={EE} alt="EE" css={{ height: '100%' }} />,
    EG: () => <img src={EG} alt="EG" css={{ height: '100%' }} />,
    EH: () => <img src={EH} alt="EH" css={{ height: '100%' }} />,
    ER: () => <img src={ER} alt="ER" css={{ height: '100%' }} />,
    ES: () => <img src={ES} alt="ES" css={{ height: '100%' }} />,
    ET: () => <img src={ET} alt="ET" css={{ height: '100%' }} />,
    FI: () => <img src={FI} alt="FI" css={{ height: '100%' }} />,
    FJ: () => <img src={FJ} alt="FJ" css={{ height: '100%' }} />,
    FK: () => <img src={FK} alt="FK" css={{ height: '100%' }} />,
    FM: () => <img src={FM} alt="FM" css={{ height: '100%' }} />,
    FO: () => <img src={FO} alt="FO" css={{ height: '100%' }} />,
    FR: () => <img src={FR} alt="FR" css={{ height: '100%' }} />,
    GA: () => <img src={GA} alt="GA" css={{ height: '100%' }} />,
    GB: () => <img src={GB} alt="GB" css={{ height: '100%' }} />,
    GD: () => <img src={GD} alt="GD" css={{ height: '100%' }} />,
    GE: () => <img src={GE} alt="GE" css={{ height: '100%' }} />,
    GF: () => <img src={GF} alt="GF" css={{ height: '100%' }} />,
    GG: () => <img src={GG} alt="GG" css={{ height: '100%' }} />,
    GH: () => <img src={GH} alt="GH" css={{ height: '100%' }} />,
    GI: () => <img src={GI} alt="GI" css={{ height: '100%' }} />,
    GL: () => <img src={GL} alt="GL" css={{ height: '100%' }} />,
    GM: () => <img src={GM} alt="GM" css={{ height: '100%' }} />,
    GN: () => <img src={GN} alt="GN" css={{ height: '100%' }} />,
    GP: () => <img src={GP} alt="GP" css={{ height: '100%' }} />,
    GQ: () => <img src={GQ} alt="GQ" css={{ height: '100%' }} />,
    GR: () => <img src={GR} alt="GR" css={{ height: '100%' }} />,
    GS: () => <img src={GS} alt="GS" css={{ height: '100%' }} />,
    GT: () => <img src={GT} alt="GT" css={{ height: '100%' }} />,
    GU: () => <img src={GU} alt="GU" css={{ height: '100%' }} />,
    GW: () => <img src={GW} alt="GW" css={{ height: '100%' }} />,
    GY: () => <img src={GY} alt="GY" css={{ height: '100%' }} />,
    HK: () => <img src={HK} alt="HK" css={{ height: '100%' }} />,
    HM: () => <img src={HM} alt="HM" css={{ height: '100%' }} />,
    HN: () => <img src={HN} alt="HN" css={{ height: '100%' }} />,
    HR: () => <img src={HR} alt="HR" css={{ height: '100%' }} />,
    HT: () => <img src={HT} alt="HT" css={{ height: '100%' }} />,
    HU: () => <img src={HU} alt="HU" css={{ height: '100%' }} />,
    ID: () => <img src={ID} alt="ID" css={{ height: '100%' }} />,
    IE: () => <img src={IE} alt="IE" css={{ height: '100%' }} />,
    IL: () => <img src={IL} alt="IL" css={{ height: '100%' }} />,
    IM: () => <img src={IM} alt="IM" css={{ height: '100%' }} />,
    IN: () => <img src={IN} alt="IN" css={{ height: '100%' }} />,
    IO: () => <img src={IO} alt="IO" css={{ height: '100%' }} />,
    IQ: () => <img src={IQ} alt="IQ" css={{ height: '100%' }} />,
    IR: () => <img src={IR} alt="IR" css={{ height: '100%' }} />,
    IS: () => <img src={IS} alt="IS" css={{ height: '100%' }} />,
    IT: () => <img src={IT} alt="IT" css={{ height: '100%' }} />,
    JE: () => <img src={JE} alt="JE" css={{ height: '100%' }} />,
    JM: () => <img src={JM} alt="JM" css={{ height: '100%' }} />,
    JO: () => <img src={JO} alt="JO" css={{ height: '100%' }} />,
    JP: () => <img src={JP} alt="JP" css={{ height: '100%' }} />,
    KE: () => <img src={KE} alt="KE" css={{ height: '100%' }} />,
    KG: () => <img src={KG} alt="KG" css={{ height: '100%' }} />,
    KH: () => <img src={KH} alt="KH" css={{ height: '100%' }} />,
    KI: () => <img src={KI} alt="KI" css={{ height: '100%' }} />,
    KM: () => <img src={KM} alt="KM" css={{ height: '100%' }} />,
    KN: () => <img src={KN} alt="KN" css={{ height: '100%' }} />,
    KP: () => <img src={KP} alt="KP" css={{ height: '100%' }} />,
    KR: () => <img src={KR} alt="KR" css={{ height: '100%' }} />,
    KW: () => <img src={KW} alt="KW" css={{ height: '100%' }} />,
    KY: () => <img src={KY} alt="KY" css={{ height: '100%' }} />,
    KZ: () => <img src={KZ} alt="KZ" css={{ height: '100%' }} />,
    LA: () => <img src={LA} alt="LA" css={{ height: '100%' }} />,
    LB: () => <img src={LB} alt="LB" css={{ height: '100%' }} />,
    LC: () => <img src={LC} alt="LC" css={{ height: '100%' }} />,
    LI: () => <img src={LI} alt="LI" css={{ height: '100%' }} />,
    LK: () => <img src={LK} alt="LK" css={{ height: '100%' }} />,
    LR: () => <img src={LR} alt="LR" css={{ height: '100%' }} />,
    LS: () => <img src={LS} alt="LS" css={{ height: '100%' }} />,
    LT: () => <img src={LT} alt="LT" css={{ height: '100%' }} />,
    LU: () => <img src={LU} alt="LU" css={{ height: '100%' }} />,
    LV: () => <img src={LV} alt="LV" css={{ height: '100%' }} />,
    LY: () => <img src={LY} alt="LY" css={{ height: '100%' }} />,
    MA: () => <img src={MA} alt="MA" css={{ height: '100%' }} />,
    MC: () => <img src={MC} alt="MC" css={{ height: '100%' }} />,
    MD: () => <img src={MD} alt="MD" css={{ height: '100%' }} />,
    ME: () => <img src={ME} alt="ME" css={{ height: '100%' }} />,
    MF: () => <img src={MF} alt="MF" css={{ height: '100%' }} />,
    MG: () => <img src={MG} alt="MG" css={{ height: '100%' }} />,
    MH: () => <img src={MH} alt="MH" css={{ height: '100%' }} />,
    MK: () => <img src={MK} alt="MK" css={{ height: '100%' }} />,
    ML: () => <img src={ML} alt="ML" css={{ height: '100%' }} />,
    MM: () => <img src={MM} alt="MM" css={{ height: '100%' }} />,
    MN: () => <img src={MN} alt="MN" css={{ height: '100%' }} />,
    MO: () => <img src={MO} alt="MO" css={{ height: '100%' }} />,
    MP: () => <img src={MP} alt="MP" css={{ height: '100%' }} />,
    MQ: () => <img src={MQ} alt="MQ" css={{ height: '100%' }} />,
    MR: () => <img src={MR} alt="MR" css={{ height: '100%' }} />,
    MS: () => <img src={MS} alt="MS" css={{ height: '100%' }} />,
    MT: () => <img src={MT} alt="MT" css={{ height: '100%' }} />,
    MU: () => <img src={MU} alt="MU" css={{ height: '100%' }} />,
    MV: () => <img src={MV} alt="MV" css={{ height: '100%' }} />,
    MW: () => <img src={MW} alt="MW" css={{ height: '100%' }} />,
    MX: () => <img src={MX} alt="MX" css={{ height: '100%' }} />,
    MY: () => <img src={MY} alt="MY" css={{ height: '100%' }} />,
    MZ: () => <img src={MZ} alt="MZ" css={{ height: '100%' }} />,
    NA: () => <img src={NA} alt="NA" css={{ height: '100%' }} />,
    NC: () => <img src={NC} alt="NC" css={{ height: '100%' }} />,
    NE: () => <img src={NE} alt="NE" css={{ height: '100%' }} />,
    NF: () => <img src={NF} alt="NF" css={{ height: '100%' }} />,
    NG: () => <img src={NG} alt="NG" css={{ height: '100%' }} />,
    NI: () => <img src={NI} alt="NI" css={{ height: '100%' }} />,
    NL: () => <img src={NL} alt="NL" css={{ height: '100%' }} />,
    NO: () => <img src={NO} alt="NO" css={{ height: '100%' }} />,
    NP: () => <img src={NP} alt="NP" css={{ height: '100%' }} />,
    NR: () => <img src={NR} alt="NR" css={{ height: '100%' }} />,
    NU: () => <img src={NU} alt="NU" css={{ height: '100%' }} />,
    NZ: () => <img src={NZ} alt="NZ" css={{ height: '100%' }} />,
    OM: () => <img src={OM} alt="OM" css={{ height: '100%' }} />,
    PA: () => <img src={PA} alt="PA" css={{ height: '100%' }} />,
    PE: () => <img src={PE} alt="PE" css={{ height: '100%' }} />,
    PF: () => <img src={PF} alt="PF" css={{ height: '100%' }} />,
    PG: () => <img src={PG} alt="PG" css={{ height: '100%' }} />,
    PH: () => <img src={PH} alt="PH" css={{ height: '100%' }} />,
    PK: () => <img src={PK} alt="PK" css={{ height: '100%' }} />,
    PL: () => <img src={PL} alt="PL" css={{ height: '100%' }} />,
    PM: () => <img src={PM} alt="PM" css={{ height: '100%' }} />,
    PN: () => <img src={PN} alt="PN" css={{ height: '100%' }} />,
    PR: () => <img src={PR} alt="PR" css={{ height: '100%' }} />,
    PS: () => <img src={PS} alt="PS" css={{ height: '100%' }} />,
    PT: () => <img src={PT} alt="PT" css={{ height: '100%' }} />,
    PW: () => <img src={PW} alt="PW" css={{ height: '100%' }} />,
    PY: () => <img src={PY} alt="PY" css={{ height: '100%' }} />,
    QA: () => <img src={QA} alt="QA" css={{ height: '100%' }} />,
    RE: () => <img src={RE} alt="RE" css={{ height: '100%' }} />,
    RO: () => <img src={RO} alt="RO" css={{ height: '100%' }} />,
    RS: () => <img src={RS} alt="RS" css={{ height: '100%' }} />,
    RU: () => <img src={RU} alt="RU" css={{ height: '100%' }} />,
    RW: () => <img src={RW} alt="RW" css={{ height: '100%' }} />,
    SA: () => <img src={SA} alt="SA" css={{ height: '100%' }} />,
    SB: () => <img src={SB} alt="SB" css={{ height: '100%' }} />,
    SC: () => <img src={SC} alt="SC" css={{ height: '100%' }} />,
    SD: () => <img src={SD} alt="SD" css={{ height: '100%' }} />,
    SE: () => <img src={SE} alt="SE" css={{ height: '100%' }} />,
    SG: () => <img src={SG} alt="SG" css={{ height: '100%' }} />,
    SH: () => <img src={SH} alt="SH" css={{ height: '100%' }} />,
    SI: () => <img src={SI} alt="SI" css={{ height: '100%' }} />,
    SJ: () => <img src={SJ} alt="SJ" css={{ height: '100%' }} />,
    SK: () => <img src={SK} alt="SK" css={{ height: '100%' }} />,
    SL: () => <img src={SL} alt="SL" css={{ height: '100%' }} />,
    SM: () => <img src={SM} alt="SM" css={{ height: '100%' }} />,
    SN: () => <img src={SN} alt="SN" css={{ height: '100%' }} />,
    SO: () => <img src={SO} alt="SO" css={{ height: '100%' }} />,
    SR: () => <img src={SR} alt="SR" css={{ height: '100%' }} />,
    SS: () => <img src={SS} alt="SS" css={{ height: '100%' }} />,
    ST: () => <img src={ST} alt="ST" css={{ height: '100%' }} />,
    SV: () => <img src={SV} alt="SV" css={{ height: '100%' }} />,
    SX: () => <img src={SX} alt="SX" css={{ height: '100%' }} />,
    SY: () => <img src={SY} alt="SY" css={{ height: '100%' }} />,
    SZ: () => <img src={SZ} alt="SZ" css={{ height: '100%' }} />,
    TA: () => <img src={TA} alt="TA" css={{ height: '100%' }} />,
    TC: () => <img src={TC} alt="TC" css={{ height: '100%' }} />,
    TD: () => <img src={TD} alt="TD" css={{ height: '100%' }} />,
    TG: () => <img src={TG} alt="TG" css={{ height: '100%' }} />,
    TH: () => <img src={TH} alt="TH" css={{ height: '100%' }} />,
    TJ: () => <img src={TJ} alt="TJ" css={{ height: '100%' }} />,
    TK: () => <img src={TK} alt="TK" css={{ height: '100%' }} />,
    TL: () => <img src={TL} alt="TL" css={{ height: '100%' }} />,
    TM: () => <img src={TM} alt="TM" css={{ height: '100%' }} />,
    TN: () => <img src={TN} alt="TN" css={{ height: '100%' }} />,
    TO: () => <img src={TO} alt="TO" css={{ height: '100%' }} />,
    TR: () => <img src={TR} alt="TR" css={{ height: '100%' }} />,
    TT: () => <img src={TT} alt="TT" css={{ height: '100%' }} />,
    TV: () => <img src={TV} alt="TV" css={{ height: '100%' }} />,
    TW: () => <img src={TW} alt="TW" css={{ height: '100%' }} />,
    TZ: () => <img src={TZ} alt="TZ" css={{ height: '100%' }} />,
    UA: () => <img src={UA} alt="UA" css={{ height: '100%' }} />,
    UG: () => <img src={UG} alt="UG" css={{ height: '100%' }} />,
    US: () => <img src={US} alt="US" css={{ height: '100%' }} />,
    UY: () => <img src={UY} alt="UY" css={{ height: '100%' }} />,
    UZ: () => <img src={UZ} alt="UZ" css={{ height: '100%' }} />,
    VA: () => <img src={VA} alt="VA" css={{ height: '100%' }} />,
    VC: () => <img src={VC} alt="VC" css={{ height: '100%' }} />,
    VE: () => <img src={VE} alt="VE" css={{ height: '100%' }} />,
    VG: () => <img src={VG} alt="VG" css={{ height: '100%' }} />,
    VI: () => <img src={VI} alt="VI" css={{ height: '100%' }} />,
    VN: () => <img src={VN} alt="VN" css={{ height: '100%' }} />,
    VU: () => <img src={VU} alt="VU" css={{ height: '100%' }} />,
    WF: () => <img src={WF} alt="WF" css={{ height: '100%' }} />,
    WS: () => <img src={WS} alt="WS" css={{ height: '100%' }} />,
    XK: () => <img src={XK} alt="XK" css={{ height: '100%' }} />,
    YE: () => <img src={YE} alt="YE" css={{ height: '100%' }} />,
    YT: () => <img src={YT} alt="YT" css={{ height: '100%' }} />,
    ZA: () => <img src={ZA} alt="ZA" css={{ height: '100%' }} />,
    ZM: () => <img src={ZM} alt="ZM" css={{ height: '100%' }} />,
    ZW: () => <img src={ZW} alt="ZW" css={{ height: '100%' }} />
};